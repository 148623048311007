import React from 'react'

export const BDF190 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1303"
    height="697"
    viewBox="0 0 1303 697"
    {...props}
  >
    <defs>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 125 145"
      >
        <image
          width="125"
          height="145"
          href="../bdf-190/PA01.181.13E.0211_19.png"
        />
      </pattern>
      <pattern
        id="pattern-2"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 197 230"
      >
        <image
          width="197"
          height="230"
          href="../bdf-190/PA01.181.13E.0211_17.png"
        />
      </pattern>
      <pattern
        id="pattern-3"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 257 263"
      >
        <image
          width="257"
          height="263"
          href="../bdf-190/PA01.181.13E.0211_13.png"
        />
      </pattern>
    </defs>
    <g
      className="vista-g"
      id="Group_88"
      data-name="Group 88"
      transform="translate(-387 -208)"
    >
      <image
        id="PA01.181.13E.0211_2"
        width="289"
        height="256"
        transform="translate(671 235)"
        href="../bdf-190/PA01.181.13E.0211_2.png"
      />
      <image
        id="PA01.181.13E.0211_1"
        width="221"
        height="272"
        transform="translate(387 319)"
        href="../bdf-190/PA01.181.13E.0211_1.png"
        data-url="https://shopirbal.com/pt/produto/cremalheira-de-ferro-fundido-170-175-190-200/"
        data-name="Cremalheira de Ferro Fundido 170-175-190-200"
      />
      <image
        id="PA01.181.13E.0211_7"
        width="177"
        height="216"
        transform="translate(552 660)"
        href="../bdf-190/PA01.181.13E.0211_7.png"
        data-url="https://shopirbal.com/pt/produto/volante-laranja/"
        data-name="Volante Laranja"
      />
      <image
        id="PA01.181.13E.0211_10"
        width="43"
        height="29"
        transform="translate(1357 594)"
        href="../bdf-190/PA01.181.13E.0211_10.png"
      />
      <image
        id="PA01.181.13E.0211_15"
        width="137"
        height="182"
        transform="translate(1394 218)"
        href="../bdf-190/PA01.181.13E.0211_15.png"
        data-url="https://shopirbal.com/pt/produto/tampa-do-motor-plastica-laranja/"
        data-name="Tampa do Motor Plástica Laranja"
      />
      <image
        id="PA01.181.13E.0211_14"
        width="48"
        height="88"
        transform="translate(1260 208)"
        href="../bdf-190/PA01.181.13E.0211_14.png"
        data-url="https://shopirbal.com/pt/produto/correia-6pj-636-elastica/"
        data-name="Correia 6PJ 636 Elástica"
      />
      <image
        id="PA01.181.13E.0211_12"
        width="72"
        height="62"
        transform="translate(1297 334)"
        href="../bdf-190/PA01.181.13E.0211_12.png"
      />
      <image
        id="PA01.181.13E.0211_11"
        width="42"
        height="31"
        transform="translate(1204 342)"
        href="../bdf-190/PA01.181.13E.0211_11.png"
        data-url="https://shopirbal.com/pt/produto/rolamento-6303-2rs/"
        data-name="Rolamento do Lado do Pinhão RL 6303 2RS"
      />
      <image
        id="PA01.181.13E.0211_8"
        width="421"
        height="533"
        transform="translate(870 372)"
        href="../bdf-190/PA01.181.13E.0211_8.png"
      />
      <image
        id="PA01.181.13E.0211_4"
        width="61"
        height="43"
        transform="translate(1022 557)"
        href="../bdf-190/PA01.181.13E.0211_4.png"
        data-url="https://shopirbal.com/pt/produto/pinhao-carreto-b1-150-cavilha/"
        data-name="Pinhão/Carreto B1-150 + Cavilha"
      />
      <image
        id="PA01.181.13E.0211_5"
        width="65"
        height="53"
        transform="translate(757 561)"
        href="../bdf-190/PA01.181.13E.0211_5.png"
        data-url="https://shopirbal.com/pt/produto/rolamento-rl-6207-2rs/"
        data-name="Rolamento do Cubo do Balde RL 6207 2RS"
      />
      <image
        id="PA01.181.13E.0211_6"
        width="30"
        height="33"
        transform="translate(735 652)"
        href="../bdf-190/PA01.181.13E.0211_6.png"
      />
      <image
        id="PA01.181.13E.0211_3"
        width="23"
        height="27"
        transform="translate(1013 386)"
        href="../bdf-190/PA01.181.13E.0211_3.png"
        data-url="https://shopirbal.com/pt/produto/interruptor-b1/"
        data-name="Interruptor B1"
      />
      <image
        id="PA01.181.13E.0211_9"
        width="49"
        height="59"
        transform="translate(1319 769)"
        href="../bdf-190/PA01.181.13E.0211_9.png"
        data-url="https://shopirbal.com/pt/produto/roda-macica-160-com-freio/"
        data-name="Roda Maciça Ø160 com Freio"
      />
      <rect
        id="PA01.181.13E.0211_19"
        width="51"
        height="59"
        transform="translate(1480 532)"
        fill="url(#pattern)"
        data-url="https://shopirbal.com/pt/produto/condensador-700wt/"
        data-name="Condensador 700W"
      />
      <rect
        id="PA01.181.13E.0211_17"
        width="79"
        height="93"
        transform="translate(1611 548)"
        fill="url(#pattern-2)"
        data-url="https://shopirbal.com/pt/produto/ventoinha-para-motor-700w/"
        data-name="Ventoinha para Motor 700W"
      />
      <rect
        id="PA01.181.13E.0211_13"
        width="105"
        height="107"
        transform="translate(1441 660)"
        fill="url(#pattern-3)"
        data-url="https://shopirbal.com/pt/produto/motor-ele-700w/"
        data-name="Motor ELE 700W"
      />
    </g>
  </svg>
)
